// This is our navbar component, this is re-usable, as it appears on all our pages

import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {

    const [cookies, setCookies] = useCookies(["access_token"]);
    const navigate = useNavigate();

    const logout = () => {
        setCookies("access_token", "");
        window.localStorage.removeItem("userID")
        navigate("/auth");
    }

    return ( 
        <div className="navbar">
            <div className="logo_container">
                <img className="nfl_logo img-fluid" alt="alt_img_text" src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png"></img>
            </div>

            <Link to="/"> Home</Link> {/* this is where you navigate to on button click */}
            <Link to="/create-player"> Create Player</Link> {/* this is where you navigate to on button click */}
            
            {!cookies.access_token ? (
                <Link to="/auth"> Login/Register</Link>
            ) : ( 
                <>
                    <Link to="/saved-players"> Saved Players</Link>
                    <button onClick={logout}> Logout </button>
                </>
            )}
            {/* ^ up here, we check if there are NO cookies.access_token (which means no user is logged in) then display <Link>, if logged in, display the <button> */}
        </div>
)}