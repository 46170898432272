// This is the Authentication Page for registering and logging
import { useState } from "react";
import axios from "axios"; // We use axios as a replacement for the "fetch()" function, we are able to get data like that
import { useCookies } from 'react-cookie' // this is how we set our TOKEN into our cookies
import { useNavigate } from 'react-router-dom';

export const Auth = () => {
    return ( 
        <div className="auth"> 
            <Login />
            <Register />

        </div>
)}

// Since the Login and Register components are *only* used within the Auth Page (this page)
// We create them under here for simplicity reasons


const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [_, setCookies] = useCookies(["access_token"])
    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
            // const response = await axios.get(backendURL + "/players")
            // const response = await axios.post("http://localhost:3009/auth/login", {
            //     username,
            //     password,
            // }); // this 'response' var will recieve everything that is sent back from the API

            const response = await axios.post(backendURL + "/auth/login", {
                username,
                password,
            }); // this 'response' var will recieve everything that is sent back from the API

            console.log(`the response is -- ${response}`) // if this is logged, I believe the TOKEN is what the response is
            setCookies("access_token", response.data.token); // here we get the token we defined on the users.js routes file on server-side (line 41)
            window.localStorage.setItem("userID", response.data.userID) // here we store our userID in local storage for quick access to it
            navigate('/')
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Form 
            username={username} 
            setUsername={setUsername} 
            password={password} 
            setPassword={setPassword}
            label="Login"
            onSubmit={onSubmit}
        />
    )
}

const Register = () => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const onSubmit = async (event) => { // ***Q: Why does this *NEED* to be defined as "onSubmit" and not handleSubmit?
        event.preventDefault(); // we do this so the page does not auto-refresh on submit
        
        try {
            const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
            // const response = await axios.get(backendURL + "/players")
            // await axios.post("http://localhost:3009/auth/register", {
            //     username,
            //     password,
            // });

            await axios.post(backendURL + "/auth/register", {
                username,
                password,
            });

            alert("Registration Completed. Now login.")
        } catch (err) {
            console.error(err)
        }

    }

    return (
        <Form 
            username={username} 
            setUsername={setUsername} 
            password={password} 
            setPassword={setPassword}
            label="Register"
            onSubmit={onSubmit}
        />
    )
}

const Form = ({ username, setUsername, password, setPassword, label, onSubmit }) => { // these are the 5 props we need passed in
    return (
        <div className="auth-container">
            <form onSubmit={onSubmit}>
                <h2>{label}</h2>
                <div className="form-group">
                    <label htmlFor="username"> Username: </label>
                     <input 
                        type="text" 
                        id="username"
                        value={username} 
                        onChange={(event) => setUsername(event.target.value)}/> 
                        {/* ^ what this means is, whenever there is any changes in our input, we're going to set our username to our "event.target.value", the value of the field */}
                </div>
                <div className="form-group">
                    <label htmlFor="password"> Password: </label>
                    <input
                        type="password" 
                        id="password" 
                        value={password} 
                        onChange={(event) => setPassword(event.target.value)}/>
                </div>

                <button type="submit">{label}</button> {/*the "{label}" that is being used here is done on lines 27 & 43 */}
            </form>
        </div>
    )
}

// One thing to understand as to why we're seperating this HTML rendering, is because we do no want the same values for the forms
