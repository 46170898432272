import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home"
import { Auth } from './pages/auth';
import { CreatePlayer } from './pages/create-player';
import { SavedPlayers } from './pages/saved-players';
import { Navbar } from "./components/navbar"


function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/auth" element={<Auth />}/>
          <Route path="/create-player" element={<CreatePlayer />}/>
          <Route path="/saved-players" element={<SavedPlayers />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
