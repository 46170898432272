// this is the Home Page
// import dotenv from 'dotenv';
import { useEffect, useState } from "react";
import axios from "axios";
import { useGetUserID } from "../hooks/useGetUserID";
import { useCookies } from "react-cookie";




export const Home = () => {
    // dotenv.config();
    const [players, setPlayers] = useState([]);
    const [users, setUsers] = useState([])
    const [savedPlayers, setSavedPlayers] = useState([]);
    const [cookies, _] = useCookies(["access_token"])

    const userID = useGetUserID();
    console.log(`users are -- ${JSON.stringify(users)}`)
    // console.log(`players are -- ${JSON.stringify(players)}`)


    useEffect(() => {
        const fetchPlayer = async () => {
            try {
                const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
                const response = await axios.get(backendURL + "/players") // here we make a get request to our players route
                setPlayers(response.data)   
                console.log(response.data)

            } catch (err) {
                console.error(err)
            }
        };

        // const fetchPlayersAndUsers = async () => {
        //     try {
        //       const [playersResponse, usersResponse] = await Promise.all([
        //         axios.get("http://localhost:3009/players"),
        //         axios.get(`http://localhost:3009/users`),
        //       ]);
        //       setPlayers(playersResponse.data);
        //       console.log(`usersResponseData is THIISSS -- ${JSON.stringify(usersResponse)}`)
        //       setUsers(usersResponse.data);
        //     } catch (err) {
        //       console.error(err);
        //     }
        //   };

        // const fetchUser = async () => {
        //     try {
        //         const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
        //         const response = await axios.get(backendURL + "/users")
        //         // const response = await axios.get(`http://localhost:3009/users`)
        //         setUsers(response.data)
        //         console.log(`this is the response.data for this USERS -- ${response.data}`)
        //     } catch (err) {
        //         console.error(err)
        //     }
        // }

        const fetchSavedPlayers = async () => {
            try {
                const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
                const response = await axios.get(backendURL + `/players/savedPlayers/ids/${userID}`)
                // const response = await axios.get(
                //     `http://localhost:3009/players/savedPlayers/ids/${userID}`
                // );
                setSavedPlayers(response.data.savedPlayers)
                // console.log(response.data)
            } catch (err) {
                console.error(err)
            }
        }

        fetchPlayer();
        
        // fetchUser();
        // fetchPlayersAndUsers();
        if (cookies.access_token) fetchSavedPlayers();  // this is how we check if the user is logged in or not, and still display the players. It prevents fetchSavedPlayers from being 
        
        // if (cookies.access_token == true) fetchSavedPlayers()
        
    }, []);

    const savePlayer = async (playerID) => {
        try {
            const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
            // const response = await axios.get(backendURL + "/players")
            const response = await axios.put(backendURL + "/players", {
                playerID, 
                userID
            }, { headers: { authorization: cookies.access_token }}); // here we make a PUT request to our players route
            setSavedPlayers(response.data.savedPlayers)
            console.log(response)  

        } catch (err) {
            console.error(err)
        }
    }

    const isPlayerSaved = (id) => savedPlayers.includes(id);

    // const getPlayerUploader = (player) => {
    //     const uploader = users.find((user) => user._id === player.userOwner);
    //     console.log(`is this workingggg -- ${player.userOwner}`)
    //     return uploader ? uploader.username : "Unknown";
    //   };

    const getPlayerUploader = (player) => {
        const uploader = users.length && users.find((user) => user._id === player.userOwner);
        return uploader ? uploader.username : "Unknown";
      };
      
    // console.log(`get player uploader noooow -- ${getPlayerUploader()}`)
    // const userr = (id) => 
    // const recipesStuff = JSON.stringify(recipes)


    // console.log(`recipes stuff is -- ${recipesStuff}`)

    return ( 
        <div> 
            <h2>Best NFL Players</h2>
            <ul>
                {players.map((item) => (
                    
                    <li key={item._id}>

                        <div className="container-top-part">
                            <div>
                                <h2> {item.name}</h2>
                                {/* <h2> Uploader: {item.userOwner}</h2> */}
                                {/* {users.map((item) => (
                                    <h2> Uploader: {item.username}</h2>
                                ))} */}
                                <h2>
                                    {/* Uploader: {getPlayerUploader(player)} */}
                                </h2>
                                
                            </div>
                            <button 
                                onClick={() => savePlayer(item._id)} 
                                disabled={isPlayerSaved(item._id)}
                            > 

                            {isPlayerSaved(item._id) ? "This Player is  Saved" : "Save"} {/* // this works as a ternary operator if statement */}
                            </button>
                        </div>
                        <div className="position">
                            <p>Position: {item.position}</p>
                        </div>
                        <div className="img-container">                        
                            <img src={item.imageUrl} alt={item.name} className="feed_image img-fluid" />
                        </div>
                        {/* <p> # of Times Saved: {item.cookingTime} (minutes) </p> */}
                    </li>
                ))} 
                {/* // ^ here, we are mapping through the players state. For each player that is in that array, we want to create an <li>     */}
            </ul> 
        </div>
)}