// Page for the create player page

import { useState } from 'react';
import axios from 'axios';
import { useGetUserID } from "../hooks/useGetUserID";
import { useNavigate } from 'react-router-dom'
import { useCookies } from "react-cookie"

export const CreatePlayer = () => {

    const userID = useGetUserID(); // we create and use the hook here as we get to use it where ever we want, this is how hooks can be useful    
    console.log(`userID is --- ${userID}`)
    
    const [cookies, _] = useCookies(["access_token"]);


    const [player, setPlayer] = useState({
        name: "", // initial empty string
        teams: [], // initial empty teams array
        position: "", // etc
        imageUrl: "",
        cookingTime: 0,
        userOwner: userID,
    });
        // ^ here we create the State that keeps track of the player we are creating
        // with the player, we want to define an initial structure to how this object ^ will look
        // like, because it will be whole object containing inputs and textareas, etc 
    
    const navigate =  useNavigate();


    const handleChange = (event) => {
        const { name, value } = event.target; // the name property is what "name" is on the input where the onChange={handleChange} is. It's the key being used above^
        setPlayer({...player, [name]: value })
    
    }

    const handleIngredientChange = (event, index) => {
        const { value } = event.target; // here we get the value via event.target
        const teams = [...player.teams]; // here we make a copy of our current teams;
        teams[index] = value;
        setPlayer({...player, teams });
        console.log(player)
    }
        
    const addIngredient = () => {
        setPlayer({...player, teams: [...player.teams, ""]}) // here we're setting the player object to be the same as it was before (this is what the spread operator helps with), but whatever you put after the comma is what is gonna change in the object
    }

    // this function is used to submit our Form
    const onSubmit = async (event) => {
        event.preventDefault();

        const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
        const response = await axios.get(backendURL + "/players")
        try {
            // await axios.post("http://localhost:3009/players", player, {
            //     headers: { authorization: cookies.access_token }
            // }); // here we make a POST request to our players route
            // alert("player created!!")
            // navigate("/")

            await axios.post(backendURL + "/players", player, {
                headers: { authorization: cookies.access_token }
            }); // here we make a POST request to our players route
            alert("player created!!")
            navigate("/")
        } catch (err) {
            console.error(err)
        }
    };

    return ( 
        <div className="create-player">
            <h2> Create Player</h2>
            <form onSubmit={onSubmit}>
                <label htmlFor="name"> Name</label>
                <input type="text" id="name" name="name" onChange={handleChange}/>

                <label htmlFor="teams">Teams</label>
                {player.teams.map((ingredient, index) => (
                    <input
                        key={index} 
                        type="text" 
                        name="teams" 
                        value={ingredient} 
                        onChange={(event) => handleIngredientChange(event, index)}
                    />
                ))}
                <button onClick={addIngredient} type="button"> Add team they played with </button>
                
                <label htmlFor="position"> Position</label>
                <textarea 
                    id="position" 
                    name="position" 
                    onChange={handleChange}>
                </textarea>
                
                <label htmlFor="imageUrl"> Image URL</label>
                <input 
                    type="text" 
                    id="imageUrl" 
                    name="imageUrl" 
                    onChange={handleChange}
                />
                
                {/* <label htmlFor="cookingTime"> Cooking Time (minutes)</label> */}
                {/* <input 
                    type="number" 
                    id="cookingTime" 
                    name="cookingTime" 
                    onChange={handleChange}
                /> */}
                <button type="submit"> Create Player </button>
                
            </form>
        </div>
    )}