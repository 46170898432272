// Page for our saved players page

import { useEffect, useState } from "react";
import axios from "axios";
import { useGetUserID } from "../hooks/useGetUserID";


export const SavedPlayers = () => {
    const [savedPlayers, setSavedPlayers] = useState([]);
    const userID = useGetUserID();

    useEffect(() => {

        const fetchSavedPlayers = async () => {
            const backendURL = process.env.BACKEND_URL || "https://bestnflplayers-app.onrender.com";
            const response = await axios.get(backendURL + "/players")

            try {
                // const response = await axios.get(
                //     `http://localhost:3009/players/savedPlayers/${userID}` // this is similar to what is done in create-player.js, but instead of just passing the player ID's, we need the entire player
                // );
                const response = await axios.get(
                    backendURL + `/players/savedPlayers/${userID}` // this is similar to what is done in create-player.js, but instead of just passing the player ID's, we need the entire player
                );
                setSavedPlayers(response.data.savedPlayers)
                console.log(response.data)
            } catch (err) {
                console.error(err)
            }
        }

        fetchSavedPlayers();
    }, []);

    return ( 
        <div> 
            <h2>Saved Players</h2>
            <ul>
                {savedPlayers.map((item) => (
                    <li key={item._id}>

                        <div>
                            <h2> {item.name}</h2>
                        </div>
                        <div className="position">
                            <p>Position: {item.position} </p>
                        </div>
                        <img src={item.imageUrl} alt={item.name} className="feed_image" />
                        {/* <p> Cooking Time: {item.cookingTime} (minutes) </p> */}
                    </li>
                ))} 
                {/* // ^ here, we are mapping through the players state. For each player that is in that array, we want to create an <li>     */}
            </ul> 
        </div>
)}